<template>
  <v-container id="Login" tag="section">
    <v-row>
      <v-col cols="7">
        <v-card>
          <v-img
            class="align-end"
            height="200"
            src="http://lgu-minailoilo.com/wp-content/uploads/2020/10/MTH2018-scaled.jpg"
            cover
          >
            <v-card-title>Municipality of Mina, Iloilo City</v-card-title>
          </v-img>

          <v-card-subtitle class="pt-4"
            ><h1>Learners Electronic Monitoring System</h1>
          </v-card-subtitle>

          <v-card-text class="font-weight-regular text-caption dark">
            <p >
              The Schools District of Mina has been creating programs and
              projects for reading and numeracy development of young Minanhon
              learners. It started years ago using only excel filing
              applications.
            </p>
            <p>
              Project LEMS is a digital innovation tool intended for municipal
              leaders, school heads, teachers, parents to easily monitor the
              progress of the struggling and non- numerate learners in the
              district and who are beneficiaries of the reading centers in each
              barangay in the municipality. This is made possible in partnership
              with the LGU Mina, through Hon. Lydia E. Grabato along with the
              Public Schools District Supervisor, Bimbo S. Casquite, Phd.
            </p>

            <p>
              LEMS is a digital tool, a web-based application which uses Vuejs a
              Java script framework for the front-end and Laravel API for
              handling the backend process. It is a work in progress and a very
              practical innovation for the teachers, LGU and other stakeholders.
            </p>

            <p>
              This innovation using the current digital applications is a
              realistic solution providing easier, convenient and secured data
              base recording method to the teachers and facilitators in the
              different schools in the Schools District of Mina. LEMS is a far
              cry from our school's old Excel File application.
            </p>
          </v-card-text>

          <v-card-actions>
            <v-btn color="orange" href="https:\\lgu-minailoilo.com" target="_blank" medium> <v-icon>mdi-lightbulb-outline</v-icon> Know More</v-btn>

          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="5">
        <v-card>
          <v-card-title>
            <div class="justify-center">
              <h2>Login</h2>
            </div>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>

            <v-form>
              <v-container class="py-5">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      class=""
                      label="Email Address"
                      hint="Email Address"
                      v-model="form.email"
                    />
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      class=""
                      label="Password"
                      :type="show_pass ? 'text' : 'password'"
                      hint="Password"
                      :append-icon="show_pass ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show_pass = !show_pass"
                      v-model="form.password"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <vue-recaptcha
                      :sitekey="siteKey"
                      ref="recaptcha"
                      @verify="onVerify"
                    ></vue-recaptcha>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- <v-col cols="" md="2"> -->
                  <!-- <p>Not yet registered?
                    <v-btn small to="/register" >Register here</v-btn>
                  </p> -->
                  <!-- </v-col> -->

                  <v-col cols="12" class="justify-end" md="2">
                    <v-btn
                      color="success"
                      class="display-1 font-weight-bold"
                      @click="login()"
                      :loading="loading"
                      :disabled="this.form.robot"
                      >Login</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
        <!-- <v-row>
        <v-col>
          <router-view></router-view>
        </v-col>
      </v-row> -->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { VueRecaptcha } from "vue-recaptcha";
import { sync, dispatch } from "vuex-pathify";

export default {
  name: "Login",
  data: () => ({
    show_pass: false,
    form: {
      email: "",
      password: "",
      robot: true,
    },
    logindisable: true,
    loading: false,
    siteKey: "6LdQ0R8jAAAAAPrjdCwT_2udPX60esS1ILpswAiv",
  }),

  components: {
    VueRecaptcha,
  },

  computed: {
    ...sync("user", ["ulogin"]),
  },

  methods: {
    async login() {
      this.loading = true;

      await dispatch("user/loginUser", this.form);

      //if successfull login
      if ((await this.ulogin) == true) {
        await this.$router.push({ name: "Dashboard" });
        await this.$toast.success("Login Successfully");
      } else {
        //if failed login
        await this.$toast.error("Failed Login");
      }
      this.loading = false;
    },

    onEvent() {
      // when you need a reCAPTCHA challenge
      this.$refs.recaptcha.execute();
    },

    onVerify(response) {
      if (response) {
        this.form.robot = false;
      } else {
        this.form.robot = true;
      }
    },
  },
};
</script>